

p{ 
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}
  
h1{ 
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    font-style: normal;
}

h4{ 
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

ul{ 
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}
button{
    color: black;
    border-radius: 45%;
    font-size: 36px;
    
}

.wrapper {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
  }


#logoutText{
    font-size: 30px;
    color: rgb(33, 44, 37);
    margin: 20px;
}


#homeLink{
    margin: 20px;
    color: white;
    font-size: 30px;
    text-decoration: none;
    
    
}
#logoutButton:hover{
    background-color: rgb(43, 44, 84);
    color: white;
    border-radius: 45%;
    font-size: 36px;
}

#createLink{
    margin: 20px;
    color: white;
    text-decoration: none;
    font-size: 30px;
   
}



#aboutLink{
    margin:0;
    color: white;
    text-decoration: none;
    font-size: 30px;
}


#supportLink{
    margin: 20px;
    color: white;
    text-decoration: none;
    font-size: 30px;
    
}
